<!--使用初始密码进入提示页面-->
<template>
    <div class="edit-pwd-succ">
        <i class="iconfont iconchenggong"></i>
        <h2>修改密码成功</h2>
        <p>修改密码成功，输入新密码登录系统</p>
        <a @click="toSetPwd">确认</a>
    </div>
</template>

<script>
import { resetTokenAndClearUser } from '@/utils';
import cookie from '@/common/NIM/utils/cookie';
export default {
    name: 'edit-pwd-succ',
    methods: {
        // 去设置密码
        toSetPwd() {
            cookie.delCookie('isNIM');
            resetTokenAndClearUser();
            window.location.reload();
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus" scoped>
.edit-pwd-succ{
    height 100%;
    display flex
    justify-content center;
    align-items center;
    flex-direction column;
    position relative
    z-index 99;
    i{
        color #21BC9A
        font-size 0.74rem;
    }
    h2{
        margin-top 0.25rem;
        font-size 0.24rem;
        color #333;
    }
    p{
        margin-top 0.2rem;
        font-size 0.16rem;
        color #979EA7;
    }
    a{
        margin-top 0.5rem
        display flex
        justify-content center
        align-items center
        width: 2.6rem;
        height: 0.5rem;
        background: #1577D2;
        border-radius: 1px;
        font-size 0.2rem;
        color #fff;
        cursor pointer;
    }
}
</style>
